import React from "react";
import styles from "./Terms.module.scss";

const Terms: React.FC = () => {
  return (
    <div className={styles.wrapper}>
      Všeobecné obchodné podmienky
      <p>Všeobecné obchodné podmienky – Stanislav Pisarčík – Čajovňa Aura :</p>
      <p>
        Preambula 1. Tieto Všeobecné obchodné podmienky (ďalej len „VOP“) sú
        obchodnými podmienkami Stanislav Pisarčík – Čajovňa Aura, so sídlom
        Štefánikova 16, 059 01 Spišská Belá, IČO: 53 803 311, zapísaného v
        živnostenskom registri Okresného úradu Kežmarok, Odbor živnostenského
        podnikania, č. živnostenského registra 730-18874 (ďalej len „Čajovňa
        Aura“ alebo „Prenajímateľ“), vydané v zmysle ust. § 273 Obchodného
        zákonníka.
      </p>
      <p>
        VOP stanovujú práva a povinnosti záväzkového vzťahu medzi Stanislav
        Pisarčík – Čajovňa Aura ako Prenajímateľom na jednej strane a fyzickou
        alebo právnickou osobou ako nájomcom na druhej strane (ďalej len
        „Nájomca“ a Nájomca spolu s Prenajímateľom ďalej len „Zmluvné strany“) a
        sú záväzné pre účastníkov tohto záväzkového vzťahu odo dňa prejavu vôle
        Nájomcu smerujúceho k uzavretiu Zmluvy o nájme hnuteľných veci v súlade
        s platnými všeobecne záväznými právnymi predpismi Slovenskej republiky.
      </p>
      <p>
        Tieto VOP sú neoddeliteľnou súčasťou Zmluvy o nájme hnuteľných veci
        uzavretej medzi Prenajímateľom a Nájomcom. VOP môžu byť akceptované aj
        online formou pri rezervácii hnuteľných veci na internete a to na
        stránke vedenej prevádzkovateľom pod doménou „www.cajovnaaura.sk“. I.
        Uzatvorenie Zmluvy o nájme hnuteľných veci 1. Prenajímateľ a Nájomca
        uzavrú za účelom prenechania hnuteľných veci Prenajímateľa za odplatu do
        dočasného užívania Nájomcovi zmluvu o nájme hnuteľných veci (ďalej len
        „Zmluva“). Práva a povinnosti Zmluvných strán, ktoré nebudú výslovne
        uvedené v Zmluve, vznikajú na základe týchto VOP. Dojednania zmluvných
        strán uvedené v Zmluve majú prednosť pred ustanoveniami týchto VOP. 1.1.
        Zmluvný vzťah medzi Prenajímateľom a Nájomcom vzniká prijatím objednávky
        Nájomcu Prenajímateľom cez webový portál www.cajovnaaura.sk ; objednávka
        odoslaná Prenajímateľovi je pre nájomcu záväzná. Prenajímateľ má právo
        kedykoľvek odmietnuť uzavretie Zmluvy, najmä ak zistí, alebo ak len má
        pochybnosti o plnoletosti Nájomcu, z kapacitných alebo organizačných
        dôvodov atď.; v každom prípade plnoletosť Nájomcu je podmienkou
        plnoletosti a účinnosti každej Zmluvy. Podpísanie písomnej formy Zmluvy
        Zmluvnými stranami pri odovzdaní objednaných hnuteľných vecí Nájomcovi
        je potvrdením ich zmluvného vzťahu založeného prijatím objednávky
        Nájomcu. 2. Predmetom nájmu sú hnuteľné veci – vodné fajky s
        príslušenstvom (ďalej len „Hnuteľná vec“ alebo „Predmet nájmu“), ktoré
        budú uvedené v Odovzdávacom protokole (ďalej len „Odovzdávací
        protokol“), ktorý podpíšu Zmluvné strany, a ktorý je neoddeliteľnou
        súčasťou Zmluvy. Nájomca vyhlasuje, že si je vedomý skutočnosti, že
        podmienkou funkčnosti Predmetu nájmu je jeho pripojenie k zdroju
        elektrickej energie, ktorá je podmienkou jeho prevádzkovania. 3.
        Prenajímateľ je povinný odovzdať Nájomcovi hnuteľné veci podľa jeho
        objednávky na mieste Prenajímateľom určenom po dohode s Nájomcom, ak sa
        zmluvné strany nedohodnú inak. Odovzdanie a prevzatie potvrdia Zmluvné
        strany pri prevzatí Hnuteľných veci Nájomcom na užívanie, ako aj po
        skončení Doby nájmu pri odovzdaní Hnuteľných veci Nájomcom
        Prenajímateľovi, vyplnením a podpísaním Odovzdávacieho protokolu.
      </p>
      <p>
        Doba nájmu, počas ktorej je Nájomca oprávnený užívať prevzaté Hnuteľné
        veci, bude uvedená v Odovzdávacom protokole (ďalej len „Doba nájmu“).
      </p>
      <p>
        Nájomca je povinný platiť za Predmet nájmu dohodnuté Nájomné, ktoré sa
        začína počítať odo dňa a času odovzdania Hnuteľných veci Nájomcovi.
      </p>
      <p>
        Prenajímateľ po dohode dodá Nájomcovi na základe jeho objednávky aj
        ďalšie príslušenstvo (napr. korunku, uhlíky, a pod.), ktorého cena bude
        stanovená v Cenníku Prenajímateľa a uvedená na webovej stránke
        Prenajímateľa prevádzkovanou na doméne www.cajovnaaura.sk (ďalej len
        „Dodatočné poplatky“).
      </p>
      <p>
        Nájomca sa zaväzuje užívať Predmet nájmu len na dohodnutý účel, a to
        obvyklým spôsobom. Používať sa smie iba tabak určený do vodných fajok.
      </p>
      {/* 2strana */}
      <br></br>
      <p>
        Nájomca vyhlasuje, že má vedomosti a zručnosti týkajúce sa používania
        Predmetu nájmu, a že je oprávnený užívať Predmet nájmu.
      </p>
      <p>
        Prenajímateľ nezodpovedá Nájomcovi za škodu (vrátane škody na zdraví)
        vzniknutú pri užívaní a prevádzke Hnuteľných vecí alebo v súvislosti s
        jeho užívaním, prevádzkou a vedením. Upozornenie: Rozpálený
        Heat-management alebo uhlíky odporúčame držať v bezpečnej vzdialenosti
        od všetkých podláh (koberce, parkety, laminát a pod.), kuchynských
        liniek, sedačiek, stolov, oblečenia, domácich miláčikov, a pod. Ani za
        tieto škody či prípadné ujmy na zdraví Prenajímateľ nezodpovedá.
      </p>
      <p>
        Za škodu, ktorá vznikne na Predmete nájmu z nedbanlivosti/zavinene,
        nesprávnym užívaním, opomenutím alebo iným konaním/nekonaním Nájomcu po
        prevzatí Predmetu nájmu do užívania Nájomcom, zodpovedá Nájomca a
        zaväzuje sa ju plnej výške nahradiť Prenajímateľovi, a to vo výške,
        lehote a spôsobom stanoveným Prenajímateľom. Konečná výška vzniknutej
        škody bude uvedená následne v Odovzdávacom protokole, ktorej maximálna
        výška je určená vo VOP. Výška náhrady vzniknutej škody, alebo
        obstarávacia cena, ktorú je Nájomca povinný uhradiť Prenajímateľovi, ak
        Prenajímateľ nestanoví inú cenu pod „poplatok za poškodenie“ v
        Odovzdávacom protokole: Vodná fajka: váza 80 EUR, poškodenie tela vodnej
        fajky v prípade poškodenia je Prenajímateľ povinný uhradiť v plnej sume
        a to 200 EUR. Poškodenie komponentov spojených s telom vodnej fajky
        (kovový tácok, ventil, downsteam, spätný ventil, srdce, heat management
        a pod.): 20 EUR. Silikónová hadica: 40 EUR. Kovový náustok: 40EUR.
        Korunka: 20 EUR. Kliešte 15 EUR. Heat-management (ďalej len „HMS“): 40
        EUR. Elektrický žhavič: 20 EUR. RGB led svetlo spolu s ovládačom
        (Nájomca je povinný uhradiť plnú výšku aj v prípade znefunkčnenia len
        jednej časti): 15 EUR. Obal na vodnú fajku: 55 EUR. Krabice a ostatné
        obaly: 8 EUR. Dokumenty (návod, dodatočný nájom a iné): 5 EUR.
      </p>
      <p>
        Povinnosti Prenajímateľa 1. Prenajímateľ sa zaväzuje: a) odovzdať
        Nájomcovi Hnuteľné veci do dočasného užívania s potrebným príslušenstvom
        a v stave spôsobilom na dohodnuté užívanie. b) zabaliť Predmet Nájmu
        alebo vybaviť na prepravu spôsobom potrebným na jeho uchovanie a
        ochranu. c) vykonávať na Predmete nájmu prehliadky, ako aj kontrolu pri
        odovzdávaní (Nájomcovi) a vrátení (Prenajímateľovi) hnuteľných veci, a
        zaznamenať nedostatky a škody v Odovzdávacom protokole.
      </p>
      <p>
        III. Povinnosti Nájomcu: 1. Nájomca je povinný najmä: a) starať sa o to,
        aby na Hnuteľných veciach nevznikla škoda. b) skontrolovať a upozorniť
        Prenajímateľa pri odovzdávaní Predmetu nájmu Nájomcovi na nedostatky a
        poškodenia, ktoré musia byť uvedené v Odovzdávacom protokole. c) užívať
        Predmet nájmu takým spôsobom a v takom rozsahu, ktorý zodpovedá
        podmienkam Predmetu nájmu, jeho technickým, prevádzkovým podmienkam a
        tiež pokynom Prenajímateľa a výrobcu Predmetu. Upozornenie: Do korunky
        sa smie dávať iba tabak určený do vodných fajok a do vázy len taká voda,
        ktorá nezašpiní, neznečistí či neznehodnotí jej povrch. d) používať
        Predmet Nájmu výhradne pre svoju potrebu. Užívanie inými osobami je
        čiastočne dovolené, ale za všetky osoby zodpovedá Nájomca. Úplné
        prenechanie Hnuteľných vecí na užívanie inej osobe (podnájom) alebo
        prevádzkovanie inej zárobkovej činnosti, ako aj jeho používanie v
        rozpore s podmienkami na to určenými, resp. pokynmi Prenajímateľa, nie
        je povolené. e) zabezpečiť Hnuteľné Veci proti odcudzeniu alebo
        poškodeniu. Pri odcudzení Nájomca ručí v plnej výške náhrady vzniknutej
        škody podľa článku I. bodu 10. vo VOP. f) zdržať sa vykonávania
        akýchkoľvek zmien na Predmete Nájmu bez predchádzajúceho písomného
        súhlasu Prenajímateľa. g) na žiadosť Prenajímateľa priniesť Predmet
        nájmu kedykoľvek počas trvania Zmluvy za účelom kontroly na mieste
        určenom Prenajímateľom pre kontrolu jeho správneho používania. h)
        bezodkladne, najneskôr pred začatím kontroly, písomne oznámiť
        Prenajímateľovi (najneskôr do doby vrátenia Hnuteľných veci) každé
        poškodenie na Predmete Nájmu.. i) v prípade škody na Hnuteľných veciach
        spôsobené v Dobe Nájmu, bude musieť byť škoda uhradená vo výške
        stanovenej v Odovzdávacom protokole. Pri škode, ktorá znemožní používať
        Hnuteľné veci v plnom rozsahu na daný účel, Nájomca zodpovedá za škodu v
        plnej výške podľa článku I. bode 10 vo VOP. j) v prípade odcudzenia
        alebo stratenia Predmetu nájmu, Nájomca zodpovedá za škodu v plnej výške
        a to podľa článku I. bod 10. vo VOP. k) pri vzniku škody v dôsledku
        požitia alkoholických nápojov alebo iných omamných a psychotropných
        látok, zodpovedá Nájomca za škodu v plnej výške a to podľa článku I. bod
        10. vo VOP. I) používať drogy a iné omamné látky spojené s Predmetom
        nájmu je prísne zakázané a za trestnú činnosť Nájomca zodpovedá sám. Ak
        Hnuteľné veci budú zadržané políciou, alebo boli použité drogy a iné
        omamné látky s Hnuteľnými vecami, je Nájomca povinný nahradiť
        Prenajímateľovi škodu v plnej výške a to podľa článku I. bod 10. vo VOP
        m) v prípade nutnosti vykonania opravy je Nájomca povinný si vyžiadať
        súhlas Prenajímateľa s vykonaním opravy (sms, mailom, telefonicky). n)
        zabezpečiť Hnuteľné veci proti odcudzeniu alebo poškodeniu. o) niesť
        zodpovednosť za škodu spôsobenú zničením, odcudzením alebo poškodením
        Hnuteľných vecí. p) oznámiť Prenajímateľovi v priebehu trvania Zmluvy
        zmenu svojho sídla, miesta, zmenu bydliska, telefónneho čísla, resp. iné
        zmeny, ktoré by mohli ovplyvniť riadne plnenie si záväzkov vyplývajúcich
        zo Zmluvy, ako aj vrátiť Hnuteľné veci včas, ktorý dátum a čas je
        uvedený v Odovzdávacom protokole alebo kontakt medzi Zmluvnými stranami.
        Nájomca bez predchádzajúceho písomného súhlasu prenajímateľa nie je
        oprávnený meniť dohodnuté miesto alebo adresu prenechania hnuteľných
        vecí, ktorá je uvedené v odovzdávacom protokole r) dbať, aby
        nedochádzalo k neprimeranému opotrebovaniu Hnuteľných veci. Pri
        poškodení nezodpovedajúcemu bežnému používaniu Predmetu Nájmu, je
        Nájomca povinný uhradiť Prenajímateľovi čiastku, ktorá bude stanovená v
        Odovzdávacom protokole. Pri škode, ktorá znemožní používanie Hnuteľných
        veci je Nájomca povinný nahradiť Prenajímateľovi škodu v plnej výške a
        to podľa článku I. bod. 10. vo VOP. s) Nájomca je povinný vrátiť
        Hnuteľné veci v takom stave, ako ich prevzal s prihliadnutím na bežné
        opotrebenie. Po použití musia byť všetky horúce a žeravé Hnuteľné veci
        (korunka, heat-management, kliešte) opláchnuté v studenej vode. Žiadna
        Hnuteľná vec sa nesmie vrátiť v horúcom alebo teplom stave. Po
        opláchnutí v studenej vode sa vložia tieto Hnuteľné veci do nádoby s
        vodou na to určenej. t) za stratu Hnuteľných vecí a príslušenstva k
        Hnuteľným veciam je Nájomca povinný zaplatiť Prenajímateľovi náhradu
        škody v plnom rozsahu a to podľa článku I. bod. 10. vo VOP. u) používať
        Predmet Nájmu v priestoroch, kde je dovolené fajčiť. Nie je dovolené si
        prenajímať Predmet nájmu na miesto, kde je zakázané fajčiť. v) Nájomca
        sa zaväzuje zaplatiť Prenajímateľovi náhradu škody za prepálenia na
        Hnuteľných veciach, ktorá výška bude uvedená v Odovzdávacom protokole.
        x) v prípade, ak Prenajímateľ veci do 24 hodín od vrátenia Hnuteľných
        zistí poškodenie na Hnuteľných veciach zo strany Nájomcu alebo tretej
        osoby, ktoré bolo Nájomcom zatajené, alebo nebolo zistiteľné pri ich
        vrátení, a z niektorého z týchto dôvodov nebolo neuvedené v Odovzdávacom
        protokole, je Nájomca povinný uhradiť Prenajímateľovi cenu za
        poškodenie, ktorá bude uvedená v novo vystavenom odovzdávacom protokole
        alebo ak Hnuteľné veci stratili svoju funkčnosť, musí Nájomca uhradiť
        škodu v plnej výške podľa bodu článku I. bod 10. vo VOP. III. Povinnosti
        Nájomcu: 1. Nájomca je povinný najmä: a) starať sa o to, aby na
        Hnuteľných veciach nevznikla škoda. b) skontrolovať a upozorniť
        Prenajímateľa pri odovzdávaní Predmetu nájmu Nájomcovi na nedostatky a
        poškodenia, ktoré musia byť uvedené v Odovzdávacom protokole. c) užívať
        Predmet nájmu takým spôsobom a v takom rozsahu, ktorý zodpovedá
        podmienkam Predmetu nájmu, jeho technickým, prevádzkovým podmienkam a
        tiež pokynom Prenajímateľa a výrobcu Predmetu. Upozornenie: Do korunky
        sa smie dávať iba tabak určený do vodných fajok a do vázy len taká voda,
        ktorá nezašpiní, neznečistí či neznehodnotí jej povrch. d) používať
        Predmet Nájmu výhradne pre svoju potrebu. Užívanie inými osobami je
        čiastočne dovolené, ale za všetky osoby zodpovedá Nájomca. Úplné
        prenechanie Hnuteľných vecí na užívanie inej osobe (podnájom) alebo
        prevádzkovanie inej zárobkovej činnosti, ako aj jeho používanie v
        rozpore s podmienkami na to určenými, resp. pokynmi Prenajímateľa, nie
        je povolené. e) zabezpečiť Hnuteľné Veci proti odcudzeniu alebo
        poškodeniu. Pri odcudzení Nájomca ručí v plnej výške náhrady vzniknutej
        škody podľa článku I. bodu 10. vo VOP. f) zdržať sa vykonávania
        akýchkoľvek zmien na Predmete Nájmu bez predchádzajúceho písomného
        súhlasu Prenajímateľa. g) na žiadosť Prenajímateľa priniesť Predmet
        nájmu kedykoľvek počas trvania Zmluvy za účelom kontroly na mieste
        určenom Prenajímateľom pre kontrolu jeho správneho používania. h)
        bezodkladne, najneskôr pred začatím kontroly, písomne oznámiť
        Prenajímateľovi (najneskôr do doby vrátenia Hnuteľných veci) každé
        poškodenie na Predmete Nájmu.. i) v prípade škody na Hnuteľných veciach
        spôsobené v Dobe Nájmu, bude musieť byť škoda uhradená vo výške
        stanovenej v Odovzdávacom protokole. Pri škode, ktorá znemožní používať
        Hnuteľné veci v plnom rozsahu na daný účel, Nájomca zodpovedá za škodu v
        plnej výške podľa článku I. bode 10 vo VOP. j) v prípade odcudzenia
        alebo stratenia Predmetu nájmu, Nájomca zodpovedá za škodu v plnej výške
        a to podľa článku I. bod 10. vo VOP. k) pri vzniku škody v dôsledku
        požitia alkoholických nápojov alebo iných omamných a psychotropných
        látok, zodpovedá Nájomca za škodu v plnej výške a to podľa článku I. bod
        10. vo VOP. I) používať drogy a iné omamné látky spojené s Predmetom
        nájmu je prísne zakázané a za trestnú činnosť Nájomca zodpovedá sám. Ak
        Hnuteľné veci budú zadržané políciou, alebo boli použité drogy a iné
        omamné látky s Hnuteľnými vecami, je Nájomca povinný nahradiť
        Prenajímateľovi škodu v plnej výške a to podľa článku I. bod 10. vo VOP
        m) v prípade nutnosti vykonania opravy je Nájomca povinný si vyžiadať
        súhlas Prenajímateľa s vykonaním opravy (sms, mailom, telefonicky). n)
        zabezpečiť Hnuteľné veci proti odcudzeniu alebo poškodeniu. o) niesť
        zodpovednosť za škodu spôsobenú zničením, odcudzením alebo poškodením
        Hnuteľných vecí. p) oznámiť Prenajímateľovi v priebehu trvania Zmluvy
        zmenu svojho sídla, miesta, zmenu bydliska, telefónneho čísla, resp. iné
        zmeny, ktoré by mohli ovplyvniť riadne plnenie si záväzkov vyplývajúcich
        zo Zmluvy, ako aj vrátiť Hnuteľné veci včas, ktorý dátum a čas je
        uvedený v Odovzdávacom protokole alebo kontakt medzi Zmluvnými stranami.
        Nájomca bez predchádzajúceho písomného súhlasu prenajímateľa nie je
        oprávnený meniť dohodnuté miesto alebo adresu prenechania hnuteľných
        vecí, ktorá je uvedené v odovzdávacom protokole r) dbať, aby
        nedochádzalo k neprimeranému opotrebovaniu Hnuteľných veci. Pri
        poškodení nezodpovedajúcemu bežnému používaniu Predmetu Nájmu, je
        Nájomca povinný uhradiť Prenajímateľovi čiastku, ktorá bude stanovená v
        Odovzdávacom protokole. Pri škode, ktorá znemožní používanie Hnuteľných
        veci je Nájomca povinný nahradiť Prenajímateľovi škodu v plnej výške a
        to podľa článku I. bod. 10. vo VOP. s) Nájomca je povinný vrátiť
        Hnuteľné veci v takom stave, ako ich prevzal s prihliadnutím na bežné
        opotrebenie. Po použití musia byť všetky horúce a žeravé Hnuteľné veci
        (korunka, heat-management, kliešte) opláchnuté v studenej vode. Žiadna
        Hnuteľná vec sa nesmie vrátiť v horúcom alebo teplom stave. Po
        opláchnutí v studenej vode sa vložia tieto Hnuteľné veci do nádoby s
        vodou na to určenej. t) za stratu Hnuteľných vecí a príslušenstva k
        Hnuteľným veciam je Nájomca povinný zaplatiť Prenajímateľovi náhradu
        škody v plnom rozsahu a to podľa článku I. bod. 10. vo VOP. u) používať
        Predmet Nájmu v priestoroch, kde je dovolené fajčiť. Nie je dovolené si
        prenajímať Predmet nájmu na miesto, kde je zakázané fajčiť. v) Nájomca
        sa zaväzuje zaplatiť Prenajímateľovi náhradu škody za prepálenia na
        Hnuteľných veciach, ktorá výška bude uvedená v Odovzdávacom protokole.
        x) v prípade, ak Prenajímateľ veci do 24 hodín od vrátenia Hnuteľných
        zistí poškodenie na Hnuteľných veciach zo strany Nájomcu alebo tretej
        osoby, ktoré bolo Nájomcom zatajené, alebo nebolo zistiteľné pri ich
        vrátení, a z niektorého z týchto dôvodov nebolo neuvedené v Odovzdávacom
        protokole, je Nájomca povinný uhradiť Prenajímateľovi cenu za
        poškodenie, ktorá bude uvedená v novo vystavenom odovzdávacom protokole
        alebo ak Hnuteľné veci stratili svoju funkčnosť, musí Nájomca uhradiť
        škodu v plnej výške podľa bodu článku I. bod 10. vo VOP.
      </p>
      <p>
        Nájomné a jeho splatnosť 1. Výška odplaty za dobu užívania Hnuteľných
        veci Nájomcom (ďalej len „Nájomné“) je uvedená v Cenníku Prenajímateľa
        platnom v čase uzavretia Zmluvy Zmluvnými stranami a zverejnenom na
        internetovej stránke Prenajímateľa, pokiaľ sa Zmluvne strany nedohodnú
        inak, a to podľa zvoleného menu, typu vodnej fajky, vybraného
        príslušenstva, príchute, akcie a množstva. Nájomné je účtované za každé,
        aj začaté obdobie dojednaného trvania nájmu. 2. Nájomca je v priebehu
        užívania Hnuteľných veci oprávnený v rámci uhradeného Nájomného používať
        aj vlastný tabak určený do vodnej fajky. 3. Nájomca má možnosť si popri
        zvolenému menu doobjednať uhlíky naviac, za cenu 0,50€/3ks kokosových
        uhlíkov. Taktiež ma možnosť doobjednať si za dohodnutú odplatu aj iné
        príslušenstvo v rámci Hnuteľných veci. Cena bude uvedená v Dodatočných
        poplatkoch. 4. V prípade, ak Zmluvné strany na základe dohody ukončia
        Zmluvu pred dohodnutou Dobou nájmu, je Nájomca povinný zaplatiť Nájomné
        vo výške dennej sadzby za skutočnú Dobu nájmu podľa Cenníka
        Prenajímateľa a uvedenú v Odovzdávacom protokole. 5. Ak Nájomné
        neprekračujúce Dobu nájmu 10 dní, Nájomca je povinný zaplatiť vopred
        stanovenú Dobu nájmu, a to pred prevzatím Hnuteľných veci od
        Prenajímateľa. 6. V prípade Doby nájmu Hnuteľných veci dohodnutej na
        viac ako 10 dni sa Nájomca zaväzuje uhrádzať Nájomné vždy najmenej 2 dni
        vopred pred začatím ďalších 10 dni užívania Hnuteľných veci, a to aj
        opakovane, pokiaľ sa Zmluvné strany nedohodnú inak. 7. V prípade Doby
        nájmu Hnuteľných vecí dohodnutej na viac ako 1 mesiac sa Nájomca
        zaväzuje uhrádzať Nájomné vždy najmenej 3 dni vopred pred začatím
        aktuálneho mesiaca užívania Hnuteľných veci, a to aj opakovane, pokiaľ
        sa Zmluvné strany nedohodnú inak. 8. Nájomca je povinný uhradiť
        Prenajímateľovi pred prevzatím Hnuteľných veci od Prenajímateľa
        Dodatočný poplatok ak je od Prenajímateľa požadovaný. Dodatočný poplatok
        bude uvedený pod „Prijatý Dodatočný poplatok“ v Odovzdávacom protokole.
        Taktiež je Nájomca povinný uhradiť cenu za dodanie Hnuteľných vecí, v
        prípade, že Nájomca požaduje prepravu do okolitých obci, ktorá je
        uvedená na internetovej stránke Prenajímateľa. Cena za dovoz Predmetu
        nájmu bude uvedená v Odovzdávacom protokole v položke „Cena za dovoz a
        odvoz“. 9. Prenajímateľ nie je povinný Nájomcovi odovzdať Hnuteľné veci
        pred zaplatením dohodnutého Nájomného a Kaucie. 10. Prenajímateľ vystaví
        Nájomcovi na Nájomné/Dodatočný poplatok/Dovoz daňový doklad. 11. Účtenka
        vystavená Prenajímateľom Nájomcovi je splatná ihneď od jej vystavenia,
        pokiaľ sa Zmluvné strany nedohodnú inak. 12. V prípade porušenia
        zmluvnej povinnosti Nájomcu zaplatiť dohodnuté Nájomné/Dodatočný
        poplatok/Dovoz riadne a včas v zmysle Zmluvy sa Nájomca zaväzuje
        zaplatiť okrem zákonných úrokov z omeškania aj zmluvnú pokutu vo výške
        5% z dlžnej sumy za každý deň omeškania až do zaplatenia, ak v Zmluve
        nebude uvedená vyššia suma.{" "}
      </p>
      <p>
        Skončenie nájmu 1. Zmluva sa uzatvára na dobu určitú a zaniká uplynutím
        doby nájmu dohodnutej v Zmluve, resp. v Odovzdávacom protokole. 2. Pred
        uplynutím dohodnutej Doby nájmu uvedenej v Zmluve môže nájom skončiť
        dohodou Zmluvných strán. 3. Prenajímateľ je oprávnený ukončiť Zmluvu
        odstúpením od Zmluvy, pokiaľ Nájomca poruší povinnosť, ktorá mu vyplýva
        zo Zmluvy alebo VOP. Odstúpenie od Zmluvy je platné a účinné dňom jeho
        doručenia Nájomcovi písomnou formou, pričom odstúpenie od Zmluvy sa
        považuje za platné a účinné aj doručením textu Odstúpenia od Zmluvy
        Prenajímateľom Nájomcovi formou sms správy na telefónne číslo alebo
        emailovú adresu uvedené v Zmluve. Po odstúpení Prenajímateľa od Zmluvy
        je Nájomca povinný ihneď vrátiť Predmet nájmu. 4. Nájomca nie je
        oprávnený vrátiť Hnuteľné veci Prenajímateľovi pred skončením Doby nájmu
        a Prenajímateľ nie je povinný od Nájomcu Box prevziať pred skončením
        Doby nájmu, pokiaľ sa Zmluvné strany nedohodnú inak. 5. V prípade, ak
        Nájomca vráti Hnuteľné veci Prenajímateľovi pred uplynutím dohodnutej
        Doby nájmu, je povinný zaplatiť Prenajímateľovi dohodnú čiastku, ktorá
        je uvedená v Odovzdávacom formulári vo výške Nájomného za celú dohodnutú
        Dobu nájmu, pokiaľ sa Zmluvné strany nedohodnú alebo ak v Zmluve nie je
        uvedené inak. 6. Ku dňu a hodine ukončenia Doby nájmu je Nájomca povinný
        vrátiť Hnuteľné veci na mieste, kde ich prevzal, pokiaľ sa Zmluvné
        strany nedohodnú inak. 7. V prípade, že Nájomca nevráti Hnuteľné veci po
        skončení Doby nájmu riadne a včas, alebo si nevyžiada písomný súhlas
        Prenajímateľa s predĺžením Doby nájmu, je Prenajímateľ oprávnený oznámiť
        orgánom činným v trestnom konaní neoprávnené užívanie Hnuteľných veci
        Nájomcom, pričom Nájomca je povinný niesť náklady za ušlý zisk a všetky
        náklady spojené s jeho uplatňovaním. Vo vyššie uvedenom prípade je
        Nájomca až do doby riadneho vrátenia Hnuteľných veci povinný zaplatiť
        Prenajímateľovi za každý aj začatý deň po skončení dohodnutej Doby nájmu
        v zmysle Zmluvy zmluvnú pokutu vo výške ušlého zisku Prenajímateľa
        rovnajúcej sa výške dohodnutého denného Nájomného, ak toto prevyšuje
        výšku zmluvnej pokuty dojednanej Zmluvnými stranami v bode 5.7. Zmluvy.
        Popri tom aj zmluvnú pokutu vo výške 30,- €/deň z omeškania s vrátením
        Hnuteľných veci, pokiaľ sa Zmluvné strany nedohodnú inak.
      </p>
      <p>
        Osobitné ustanovenia o ochrane osobných údajov (GDPR): ZÁSADY OCHRANY
        OSOBNÝCH ÚDAJOV Stanislav Pisarčík – Čajovňa Aura, si je ako
        poskytovateľ plne vedomý svojich povinností a preto vyvíja maximálnu
        snahu o rešpektovanie práva na súkromie všetkých svojich zákazníkov a
        ďalšie osoby (ďalej tiež len ako „dotknuté osoby“), ktoré sú alebo môžu
        byť dotknutými osobami v zmysle Nariadenia Európskeho parlamentu a Rady
        (EÚ) 2016/679 zo dňa 27. 4. 2016 o ochrane fyzických osôb pri spracúvaní
        osobných údajov a o voľnom pohybe týchto údajov, ktorým sa zrušuje
        smernica 95/46/ES (všeobecné nariadenie o ochrane údajov) (ďalej len
        „Nariadenie“), zákonom č. 18/2018 Z.z. o ochrane osobných údajov a o
        zmene a doplnení niektorých zákonov (ďalej len „Zákon“) a príp. aj
        ďalšími súvisiacimi všeobecne záväznými právnymi predpismi, pri
        zachovaní rešpektovania zákona č. 122/2013 Z.z. o ochrane osobných
        údajov a o zmene a doplnení niektorých zákonov v znení neskorších
        predpisov. Je pre nás dôležité, aby každý, no najmä dotknuté osoby (viď
        definíciu nižšie), poznali a chápali, ktoré osobné údaje o nich
        spracovávame, pre aké účely, z akého dôvodu tak robíme a pod. (ako bude
        uvedené ďalej), no najmä aby poznali svoje práva a postupy ochrany, ako
        zabezpečiť ich naplnenie či ochranu. Preto odporúčame, vyzývame a
        žiadame každého – každú fyzickú osobu – najmä dotknuté osoby, ktoré majú
        záujem stať sa našim zákazníkom, popr. zamestnancom, aby si pred
        akýmkoľvek ďalším krokom voči nám (najmä pred zaslaním emailu, prihlášky
        a pod.) dôkladne prečítal tieto Zásady ochrany osobných údajov, ktoré
        vám poskytnú viac informácií o spracovávaní Vašich osobných údajov.
        Pripúšťame, že tento dokument môže byť aktualizovaný, a to aj opakovane,
        z toho dôvodu si Vás dovoľujeme požiadať, najmä dotknuté osoby, aby sa s
        nimi priebežne oboznamovali; túto požiadavku berte, prosím, tak že ju
        robíme v prospech Vašich práv, najmä ak ste v postavení dotknutých osôb.
        Čajovňa Aura rešpektuje Vaše súkromie, bez ohľadu na to, či Vaše osobné
        údaje spracovávame sami alebo v mene iných strán. Pre nás je dôležité,
        aby ste chápali, aké osobné údaje o vás spracovávame, prečo to robíme a
        aké sú Vaše práva. Preto Vás vyzývame, aby ste si prečítali tieto Zásady
        ochrany osobných údajov, ktoré Vám poskytnú viac informácií o spracovaní
        Vašich osobných údajov. Zároveň vyhlasujeme, že získané osobné údaje
        nikdy nescudzíme, nevyužijeme na komerčné účely ani nepoužijeme v
        rozpore s platnými predpismi na ochranu údajov uvedenými vyššie. Berte
        tiež, prosím, na vedomie, že spracovanie Vašich osobných údajov nie je
        hlavnou činnosťou Čajovňa Aura, je však nevyhnutnou súčasťou, aby sme
        Vám, našim zákazníkom/klientom mohli poskytnúť naše služby v čo
        najvyššej kvalite, k čomu potrebujeme aj niektoré Vaše osobné údaje. Ako
        Nájomca týmto (svojim podpisom na konci tohto dokumentu) aktívne
        potvrdzujem, že s týmito Zásadami ochrany osobných údajov súhlasím: a)
        slobodne udeľujem prevádzkovateľovi (Stanislav Pisarčík – Čajovňa Aura)
        ako oprávnenej osobe súhlas so spracúvaním mojich osobných údajov na
        účely uvedené v týchto Zásadách ochrany osobných údajov v súlade so
        Zákonom a Nariadením, b) potvrdzujem, že mnou vyplnené osobné údaje sú
        správne a aktuálne; Vzhľadom na to, že podmienkou platnosti a účinnosti
        Zmluvy je v zmysle čl. I bodu 1.1. VOP plnoletosť Nájomcu, jeho
        potvrdenie o tom, že ako oprávnená osoba má pre účely GDPR menej ako 16
        rokov a že jej bol zákonným zástupcom udelený súhlas so spracovaním jej
        osobných údajov, sa nevyžaduje. Vymedzenie niektorých základných pojmov:
        Osobnými údajmi sú údaje týkajúce sa identifikovanej fyzickej osoby
        alebo identifikovateľnej fyzickej osoby, ktorú možno identifikovať
        priamo alebo nepriamo, najmä na základe všeobecne použiteľného
        identifikátora, iného identifikátora, ako je napríklad meno, priezvisko,
        identifikačné číslo, lokalizačné údaje, alebo online identifikátor,
        alebo na základe jednej alebo viacerých charakteristík alebo znakov,
        ktoré tvoria jej fyzickú identitu, fyziologickú identitu, genetickú
        identitu, psychickú identitu, mentálnu identitu, ekonomickú identitu,
        kultúrnu identitu alebo sociálnu identitu. V rámci ochrany Vašich
        osobných údajov, resp. súkromia, si Vám dovoľujeme uviesť niektoré
        základné pojmy, s ktorými sa môžete stretnúť v súvislosti s našimi
        aktivitami (definície podľa Zákona): a) súhlas dotknutej osoby akýkoľvek
        vážny a slobodne daný, konkrétny, informovaný a jednoznačný prejav vôle
        dotknutej osoby vo forme vyhlásenia alebo jednoznačného potvrdzujúceho
        úkonu, ktorým dotknutá osoba vyjadruje súhlas so spracúvaním svojich
        osobných údajov, b) spracúvanie osobných údajov spracovateľská operácia
        alebo súbor spracovateľských operácií s osobnými údajmi alebo so súbormi
        osobných údajov, najmä získavanie, zaznamenávanie, usporadúvanie,
        štruktúrovanie, uchovávanie, zmena, vyhľadávanie, prehliadanie,
        využívanie, poskytovanie prenosom, šírením alebo iným spôsobom,
        preskupovanie alebo kombinovanie, obmedzenie, vymazanie, bez ohľadu na
        to, či sa vykonáva automatizovanými prostriedkami alebo
        neautomatizovanými prostriedkami, c) pseudonymizácia spracúvanie
        osobných údajov spôsobom, že ich nie je možné priradiť ku konkrétnej
        dotknutej osobe bez použitia dodatočných informácií, ak sa takéto
        dodatočné informácie uchovajú oddelene a vzťahujú sa na ne technické a
        organizačné opatrenia na zabezpečenie toho, aby osobné údaje nebolo
        možné priradiť identifikovanej fyzickej osobe alebo identifikovateľnej
        fyzickej osobe, d)šifrovanie transformácia osobných údajov spôsobom,
        ktorým opätovné spracúvanie je možné len po zadaní zvoleného parametra,
        ako je kľúč alebo heslo, e)profilovanie akákoľvek forma automatizovaného
        spracúvania osobných údajov spočívajúceho v použití osobných údajov na
        vyhodnotenie určitých osobných znakov alebo charakteristík týkajúcich sa
        fyzickej osoby, najmä na analýzu alebo predvídanie znakov alebo
        charakteristík dotknutej osoby súvisiacich s jej výkonnosťou v práci,
        majetkovými pomermi, zdravím, osobnými preferenciami, záujmami,
        spoľahlivosťou, správaním, polohou alebo pohybom, f) obmedzenie
        spracúvania osobných údajov označenie uchovávaných osobných údajov s
        cieľom obmedziť ich spracúvania v budúcnosti, g) porušenie ochrany
        osobných údajov porušenie bezpečnosti, ktoré vedie k náhodnému alebo
        nezákonnému zničeniu, strate, zmene alebo k neoprávnenému poskytnutiu
        prenášaných, uchovávaných osobných údajov alebo inak spracúvaných
        osobných údajov, alebo k neoprávnenému prístupu k nim, h) dotknutá osoba
        každá fyzická osoba, ktorej osobné údaje sa spracúvajú, i)
        prevádzkovateľ každý, kto sám alebo spoločne s inými vymedzí účel a
        prostriedky spracúvania osobných údajov a spracúva osobné údaje vo
        vlastnom mene; prevádzkovateľ alebo konkrétne požiadavky na jeho určenie
        môžu byť ustanovené v osobitnom predpise alebo medzinárodnej zmluvy,
        ktorou je Slovenská republika viazaná, ak takýto predpis alebo táto
        zmluva ustanovuje účel a prostriedky spracúvania osobných údajov;
        Prevádzkovateľom je aj Stanislav Pisarčík – Čajovňa Aura, pričom je
        možné, že osobné údaje budeme spracovávať aj ako spoloční
        prevádzkovatelia, j) spoloční prevádzkovatelia dvaja alebo viacerí
        prevádzkovatelia, ktorí si dohodou určia účel a prostriedky spracúvania
        osobných údajov, k) sprostredkovateľ každý, kto spracúva osobné údaje v
        mene prevádzkovateľa, l) príjemca každý, komu sa osobné údaje poskytnú
        bez ohľadu na to, či je treťou stranou; za príjemcu sa nepovažuje orgán
        verejnej moci, ktorý spracúva osobné údaje na základe osobitného
        predpisu alebo medzinárodnej zmluvy, ktorou je Slovenská republika
        viazaná, v súlade s pravidlami ochrany osobných údajov vzťahujúcimi sa
        na daný účel spracúvania osobných údajov, m) tretia strana každý, kto
        nie je dotknutou osobou, prevádzkovateľ, sprostredkovateľ alebo inou
        fyzickou osobou, ktorá na základe poverenia prevádzkovateľa alebo
        sprostredkovateľa spracúva osobné údaje. n) ďalšie definície nájdete na
        http://www.zakonypreludi.sk/hladanie?text=18%2F2018. Zásady spracúvania
        osobných údajov: a) Zákonnosť Osobné údaje možno spracúvať len zákonným
        spôsobom a tak, aby nedošlo k porušeniu základných práv dotknutej osoby.
        Zákonné spracúvanie osobných údajov je, ak je vykonávané na základe
        aspoň jedného z týchto právnych základov: -dotknutá osoba vyjadrila
        súhlas so spracúvaním svojich osobných údajov aspoň na jeden konkrétny
        účel, -spracúvanie osobných údajov je nevyhnutné na plnenie zmluvy v
        súvislosti s dotknutou osobou,-spracúvanie osobných údajov je nevyhnutné
        podľa osobitného predpisu alebo medzinárodnej zmluvy, ktorou je
        Slovenská republika viazaná, -spracúvanie osobných údajov je nevyhnutné
        na ochranu života, zdravia alebo majetku dotknutej osoby alebo inej
        fyzickej osoby, -spracúvanie osobných údajov je nevyhnutné na splnenie
        úlohy vo verejnom záujme alebo pri výkone verejnej moci zverenej
        prevádzkovateľovi, alebo-spracúvanie osobných údajov je nevyhnutné na
        účel oprávnených záujmov prevádzkovateľa alebo tretej strany okrem
        prípadov, keď nad týmito záujmami prevažujú záujmy alebo práva dotknutej
        osoby vyžadujúce si ochranu osobných údajov, najmä ak je dotknutou
        osobou dieťa; tento právny základ sa nevzťahuje na spracúvanie osobných
        údajov orgánmi verejnej moci pri plnení ich úloh. b) Zásada obmedzenia
        účelu Osobné údaje sa môžu získavať len na konkrétne určený, výslovne
        uvedený a oprávnený, legitímny účel a nesmú sa ďalej spracúvať spôsobom,
        ktorý nie je zlučiteľný s týmto účelom. Výnimku tvoria tzv.
        privilegované účely, ktoré ochrana osobných údajov automaticky považuje
        za zlučiteľné s pôvodnými účelmi. Ide o účely archivácie vo verejnom
        záujme, účely vedeckého alebo historického výskumu a štatistické účely.
        c) Zásada minimalizácie osobných údajov Spracúvané osobné údaje musia
        byť primerané, relevantné a obmedzené na nevyhnutný rozsah daný účelom,
        na ktorý sa spracúvaj. d) Zásada správnosti Spracúvané osobné údaje
        musia byť správne a podľa potreby aktualizované, pričom sa musia prijať
        primerané a účinné opatrenia na zabezpečenie toho, aby sa osobné údaje,
        ktoré sú nesprávne z hľadiska účelov, na ktoré sa spracúvajú, bez
        zbytočného odkladu vymazali alebo opravili. Táto zásada však nezbavuje
        dotknutú osobu zodpovednosti poskytovať správne osobné údaje. e)Zásada
        minimalizácie uchovávania Osobné údaje musia byť uchovávané vo forme,
        ktorá umožňuje identifikáciu dotknutých osôb najviac dovtedy, kým je to
        potrebné na účely, na ktoré sa osobné údaje spracúvajú. Doba uchovávania
        môže vyplývať z osobitných predpisov, alebo ak sa majú spracúvať výlučne
        na účel archivácie, na vedecký účel, na účel historického výskumu alebo
        na štatistický účel a ak sú pri tom dodržané primerané záruky ochrany
        práv dotknutej osoby spočívajúce v zavedení primeraných a účinných
        technických a organizačných opatrení, najmä na zabezpečenie dodržiavania
        zásady minimalizácie údajov. f)Zásada integrity a dôvernosti Osobné
        údaje musia byť spracúvané spôsobom, ktorý prostredníctvom primeraných
        technických a organizačných opatrení zaručuje primeranú bezpečnosť
        osobných údajov vrátane ochrany pred neoprávneným spracúvaním osobných
        údajov, nezákonným spracúvaním osobných údajov, náhodnou stratou
        osobných údajov, výmazom osobných údajov alebo poškodením osobných
        údajov. g)Zásada zodpovednosti Prevádzkovateľ je zodpovedný za
        dodržiavanie základných Zásad spracúvania osobných údajov, za súlad
        spracúvania osobných údajov so Zásadami spracúvania osobných údajov a je
        povinný tento súlad so Zásadami spracúvania osobných údajov na
        požiadanie Úradu na ochranu osobných údajov Slovenskej republiky
        preukázať. Kategórie dotknutých osôb a osobných údajov, na aké účely ich
        spracúvame, právny základ spracúvania: V rámci našej činnosti
        spracovávame osobné údaje týchto dotknutých osôb (kategórie): Právnická
        osoba Stanislav Pisarčík – Čajovňa Aura. Zmluvní partneri – subjekty, s
        ktorými vstupujeme do zmluvných vzťahov, vrátane subjektov, s ktorými
        sme do zmluvných vzťahov nevstúpili, ale boli predmetom rokovaní. V
        rámci našej činnosti spracovávame nasledovné osobné údaje (kategórie):
        Osobné údaje – napr. meno, priezvisko, pohlavie, trvalý alebo obdobný
        pobyt (sídlo, miesto podnikania), emailová adresa, telefónne číslo,
        titul, funkcia, dátum narodenia, Zmluvné údaje – osobné údaje, meno a
        priezvisko fyzickej osoby a ak ide o podnikateľa aj jeho obchodné meno,
        obchodné meno právnickej osoby, sídlo, pobyt, doručovacia adresa, obsah
        zmluvy (vecný, časový), platobné a fakturačné údaje, údaje o
        korešpondencii a komunikácii, zápis v príslušnej evidencii (obchodný
        register, živnostenský register, evidencia podľa osobitného zákona),
        Zákonom vyžadované údaje – všetky údaje vyžadované všeobecne záväznými
        právnymi predpismi a právnymi normami Európskej únie, Platobné a
        fakturačné údaje – číslo bankového spojenia, identifikačné a daňové
        identifikačné číslo, identifikačné číslo pre DPH, Údaje o korešpondencii
        a komunikácii – emailová adresa, korešpondenčná adresa. Osobné údaje
        môžu byť spracúvané aj v automatizovanej podobe, vychádzajúcej z našej
        internetovej stránky, do ktorej má prístup štatutár a/alebo admin,
        ktorého môže môžu naša spoločnosť udeliť oprávnenie na všetky úkony v
        systéme. Účel a cieľ spracúvania osobných údajov: a)Objednávanie našich
        služieb: -meno, priezvisko (za účelom identifikácie nájomcu), -dátum
        narodenia (za účelom identifikácie nájomcu a vylúčenia uzavretia zmluvy
        s neplnoletou osobou), -emailová adresa a telefonický kontakt (za účelom
        vzájomnej komunikácie),-adresa trvalého pobytu alebo obdobného pobytu
        (za účelom vystavenia a zaslania daňového dokladu a plnenia
        zmluvy).Právnym základom pre tento účel je nájomná zmluva medzi našou
        spoločnosťou a nájomcami. Budeme spracúvať osobné údaje o všetkých
        našich zákazníkoch. Lehota na výmaz osobných údajov je 12 rokov.
        b)Informácie o zmluvných partneroch – evidencia a administratíva: -meno,
        priezvisko, titul (fyzická osoba)/obchodné meno (právnická osoba),
        miesto podnikania (fyzická osoba)/sídlo (právnická osoba), IČO, DIČ, IČ
        pre DPH (za účelom vystavenia a zaslania daňového dokladu) a iné údaje
        podľa osobitných predpisov,-platobné údaje. Právnym základom pre tento
        účel je plnenie zmluvných a zákonných povinností, Obchodný zákonník,
        Občiansky zákonník. Budeme spracúvať osobné údaje, zmluvné údaje,
        platobné a fakturačné údaje, údaje o korešpondencii a komunikácii o
        našich zákazníkoch, dodávateľoch a odberateľoch, spolupracujúcich
        osobách a pod. Lehota na výmaz osobných údajov je 10 rokov. c)Mzdy,
        personalistika a účtovníctvo, dane, zdravotné a sociálne poistenie
        (povinnosti finančnej povahy): -meno, priezvisko, titul zamestnanca,
        adresa trvalého pobytu, výška mzdy/príjmu, manželia, manželky,
        vyživované deti zamestnancov, rodičia vyživovaných detí zamestnancov,
        blízke osoby. Právnym základom pre tento účel je plnenie zákonných
        povinností (napr. Zákonník práce, zákon o zdravotnom poistení, zákon o
        sociálnom poistení, o dani z príjmov a pod.). Budeme spracúvať údaje
        vyžadované podľa právnych predpisov a predpisov Európskej únie na účely
        plnenia našich povinností.. Lehota na výmaz osobných údajov je 10 rokov.
        d)Marketing: -Obrazové vyobrazenia zákazníkov. Právnym základom pre
        tento účel je predovšetkým Občiansky zákonník a súhlas dotknutej osoby
        alebo v odôvodnených prípadoch náš oprávnený záujem. Osobné údaje na
        marketingové účely budeme spracúvať na základe vopred vyžiadaných
        osobitných súhlasov dotknutých osôb. Lehota na výmaz osobných údajov je
        12 rokov. e) Korporátne informácie: – Meno, priezvisko, titul, rodné
        číslo, miesto a dátum narodenia, trvalý pobyt, meno a priezvisko rodičov
        spoločníkov, – Meno, priezvisko, titul, rodné číslo, miesto a dátum
        narodenia, trvalý pobyt, meno a priezvisko rodičov konateľov. Právnym
        základom pre tento účel je Obchodný zákonník, živnostenský zákon. Lehota
        na výmaz osobných údajov je daný trvaním právnej subjektivity oprávnenej
        osoby. Stanislav Pisarčík – Čajovňa Aura ako prevádzkovateľ spracúva
        Vaše osobné údaje, ako dotknutej osoby, prostredníctvom: 1.Konateľov,
        2.vedenie účtovníctva, miezd a verejného poistenia pre oprávnenú osobu
        na zmluvnom alebo zákonnom základe. Vaše osobné údaje, ako osobné údaje
        dotknutých osôb, budú uchovávané obmedzenú dobu a budú vymazané
        najneskôr do 30 dní po tom, ako prestanú byť potrebné pre účely, na
        ktoré nám boli odovzdané. Vo väčšine prípadov to bude na obdobie trvania
        nášho zmluvného vzťahu, pokiaľ osobitný právny predpis nebude dobu
        uchovávania dokladov inak. Osobné údaje môžeme spracovávať aj dlhšiu
        dobu v prípade nášho oprávneného záujmu. Práva dotknutých osôb: Vaše
        práva si môžete uplatniť zaslaním emailovej správy alebo písomnej
        žiadosti na naše kontaktné údaje, ktorými sú Stanislav Pisarčík –
        Čajovňa Aura ,so sídlom Štefánikova 16, 05901, Spišská Belá. V žiadosti
        je potrebné uviesť meno, priezvisko, e-mailovú adresu, prípadne
        telefonický kontakt a adresu trvalého bydliska. Ak nám uvedené údaje
        neposkytnete, nebude možné vyhovieť Vašej žiadosti. Tieto informácie od
        Vás požadujeme preto, aby sme si overili Vašu totožnosť a neposkytli
        Vaše osobné údaje neoprávnenej osobe. Vaše práva ako dotknutej osoby sú
        legislatívne upravené v ust. § 19 a nasl. Zákona a čl. 12 a nasl.
        Nariadenia takto: Právo na prístup k údajom Máte právo získať od nás
        potvrdenie o tom, či sa o Vás spracúvajú osobné údaje a v prípade, ak
        Vaše osobné údaje spracúvame, poskytneme vám informáciu o tom, aké údaje
        o Vás spracúvame, za akým účelom, komu boli Vaše osobné údaje
        poskytnuté, či boli prenesené do tretej krajiny a ako dlho budeme Vaše
        osobné údaje uchovávať. Právo na opravu Ak sa domnievate, že o vás
        spracúvame nesprávne osobné údaje, môžete využiť toto právo priamo
        obrátením sa na nás a požiadaním o ich opravu. Právo na vymazanie (právo
        na zabudnutie) Máte právo na vymazanie Vašich osobných údajov, ktoré o
        vás spracúvame, v prípade ak sú splnené nasledovné podmienky a
        neuplatňujú sa zákonné výnimky: -údaje už nie sú potrebné na účely, na
        ktoré sa získavali, -odvoláte súhlas na spracúvanie Vašich osobných
        údajov a na ich spracúvanie neexistuje iný právny základ, -namietnete na
        základe Vašej konkrétnej situácie spracúvanie Vašich osobných údajov,
        spracúvaných na základe oprávneného záujmu a neprevažujú oprávnené
        dôvody na spracúvanie alebo namietnete spracúvanie za účelom priameho
        marketingu, -osobné údaje sa spracúvali nezákonne. V prípade, ak by boli
        Vaše osobné údaje zverejnené a Vy si u nás uplatníte právo na vymazanie,
        vymažeme aj takéto zverejnené osobné údaje. Zároveň budeme informovať
        ostatných príjemcov Vašich osobných údajov, že ste si uplatnili právo na
        vymazanie a mali by teda vymazať všetky odkazy na Vaše osobné údaje, ak
        to bude technicky možné so zreteľom na dostupnú technológiu a náklady na
        vykonanie týchto opatrení. V prípade, ak si u nás zrušíte konto,
        pristúpime k vymazaniu údajov, pri ktorých je to možné a k zrušeniu
        konta do 10 dní. Z technických dôvodov (napr. z dôvodu zálohovania
        údajov) sa však môže stať, že niektoré údaje budú vymazané po dlhšej
        dobe, ktorá by však nemala presiahnuť 60 dní. Právo na obmedzenie
        spracúvania Máte tiež právo nás požiadať o dočasné obmedzenie
        spracúvania, a to v týchto prípadoch: -ak sa domnievate, že o Vás
        spracúvame nesprávne osobné údaje, do doby, než sa overí správnosť
        týchto osobných údajov, -spracúvanie Vašich osobných je protizákonné a
        vy sa rozhodnete namiesto vymazania osobných údajov žiadať obmedzenie
        ich spracúvania, -už nepotrebujeme osobné údaje na účely spracúvania,
        avšak tieto sú potrebné pre Vaše vlastné účely preukázania, uplatňovania
        alebo obhajovania Vašich právnych nárokov, -v prípade ak namietate
        spracúvanie Vašich osobných údajov, do doby overenia či oprávnené dôvody
        pre spracúvanie osobných údajov prevažujú nad Vašimi oprávnenými
        záujmami. Právo na prenosnosť údajov Máte právo získať osobné údaje,
        ktoré o Vás spracúvame na základe súhlasu a/alebo zmluvy a spracúvame
        ich automatizovanými prostriedkami, a to v štruktúrovanom, bežne
        používanom a strojovo čitateľnom formáte. Tieto údaje môžete preniesť aj
        inej osobe, preto ak je to technicky možné, prenesieme na Vašu žiadosť
        Vaše osobné údaje priamo k Vami zvolenému prevádzkovateľovi.
      </p>
      <p>
        Osobitné ustanovenia o ochrane osobných údajov (GDPR): ZÁSADY OCHRANY
        OSOBNÝCH ÚDAJOV Stanislav Pisarčík – Čajovňa Aura, si je ako
        poskytovateľ plne vedomý svojich povinností a preto vyvíja maximálnu
        snahu o rešpektovanie práva na súkromie všetkých svojich zákazníkov a
        ďalšie osoby (ďalej tiež len ako „dotknuté osoby“), ktoré sú alebo môžu
        byť dotknutými osobami v zmysle Nariadenia Európskeho parlamentu a Rady
        (EÚ) 2016/679 zo dňa 27. 4. 2016 o ochrane fyzických osôb pri spracúvaní
        osobných údajov a o voľnom pohybe týchto údajov, ktorým sa zrušuje
        smernica 95/46/ES (všeobecné nariadenie o ochrane údajov) (ďalej len
        „Nariadenie“), zákonom č. 18/2018 Z.z. o ochrane osobných údajov a o
        zmene a doplnení niektorých zákonov (ďalej len „Zákon“) a príp. aj
        ďalšími súvisiacimi všeobecne záväznými právnymi predpismi, pri
        zachovaní rešpektovania zákona č. 122/2013 Z.z. o ochrane osobných
        údajov a o zmene a doplnení niektorých zákonov v znení neskorších
        predpisov. Je pre nás dôležité, aby každý, no najmä dotknuté osoby (viď
        definíciu nižšie), poznali a chápali, ktoré osobné údaje o nich
        spracovávame, pre aké účely, z akého dôvodu tak robíme a pod. (ako bude
        uvedené ďalej), no najmä aby poznali svoje práva a postupy ochrany, ako
        zabezpečiť ich naplnenie či ochranu. Preto odporúčame, vyzývame a
        žiadame každého – každú fyzickú osobu – najmä dotknuté osoby, ktoré majú
        záujem stať sa našim zákazníkom, popr. zamestnancom, aby si pred
        akýmkoľvek ďalším krokom voči nám (najmä pred zaslaním emailu, prihlášky
        a pod.) dôkladne prečítal tieto Zásady ochrany osobných údajov, ktoré
        vám poskytnú viac informácií o spracovávaní Vašich osobných údajov.
        Pripúšťame, že tento dokument môže byť aktualizovaný, a to aj opakovane,
        z toho dôvodu si Vás dovoľujeme požiadať, najmä dotknuté osoby, aby sa s
        nimi priebežne oboznamovali; túto požiadavku berte, prosím, tak že ju
        robíme v prospech Vašich práv, najmä ak ste v postavení dotknutých osôb.
        Čajovňa Aura rešpektuje Vaše súkromie, bez ohľadu na to, či Vaše osobné
        údaje spracovávame sami alebo v mene iných strán. Pre nás je dôležité,
        aby ste chápali, aké osobné údaje o vás spracovávame, prečo to robíme a
        aké sú Vaše práva. Preto Vás vyzývame, aby ste si prečítali tieto Zásady
        ochrany osobných údajov, ktoré Vám poskytnú viac informácií o spracovaní
        Vašich osobných údajov. Zároveň vyhlasujeme, že získané osobné údaje
        nikdy nescudzíme, nevyužijeme na komerčné účely ani nepoužijeme v
        rozpore s platnými predpismi na ochranu údajov uvedenými vyššie. Berte
        tiež, prosím, na vedomie, že spracovanie Vašich osobných údajov nie je
        hlavnou činnosťou Čajovňa Aura, je však nevyhnutnou súčasťou, aby sme
        Vám, našim zákazníkom/klientom mohli poskytnúť naše služby v čo
        najvyššej kvalite, k čomu potrebujeme aj niektoré Vaše osobné údaje. Ako
        Nájomca týmto (svojim podpisom na konci tohto dokumentu) aktívne
        potvrdzujem, že s týmito Zásadami ochrany osobných údajov súhlasím: a)
        slobodne udeľujem prevádzkovateľovi (Stanislav Pisarčík – Čajovňa Aura)
        ako oprávnenej osobe súhlas so spracúvaním mojich osobných údajov na
        účely uvedené v týchto Zásadách ochrany osobných údajov v súlade so
        Zákonom a Nariadením, b) potvrdzujem, že mnou vyplnené osobné údaje sú
        správne a aktuálne; Vzhľadom na to, že podmienkou platnosti a účinnosti
        Zmluvy je v zmysle čl. I bodu 1.1. VOP plnoletosť Nájomcu, jeho
        potvrdenie o tom, že ako oprávnená osoba má pre účely GDPR menej ako 16
        rokov a že jej bol zákonným zástupcom udelený súhlas so spracovaním jej
        osobných údajov, sa nevyžaduje. Vymedzenie niektorých základných pojmov:
        Osobnými údajmi sú údaje týkajúce sa identifikovanej fyzickej osoby
        alebo identifikovateľnej fyzickej osoby, ktorú možno identifikovať
        priamo alebo nepriamo, najmä na základe všeobecne použiteľného
        identifikátora, iného identifikátora, ako je napríklad meno, priezvisko,
        identifikačné číslo, lokalizačné údaje, alebo online identifikátor,
        alebo na základe jednej alebo viacerých charakteristík alebo znakov,
        ktoré tvoria jej fyzickú identitu, fyziologickú identitu, genetickú
        identitu, psychickú identitu, mentálnu identitu, ekonomickú identitu,
        kultúrnu identitu alebo sociálnu identitu. V rámci ochrany Vašich
        osobných údajov, resp. súkromia, si Vám dovoľujeme uviesť niektoré
        základné pojmy, s ktorými sa môžete stretnúť v súvislosti s našimi
        aktivitami (definície podľa Zákona): a) súhlas dotknutej osoby akýkoľvek
        vážny a slobodne daný, konkrétny, informovaný a jednoznačný prejav vôle
        dotknutej osoby vo forme vyhlásenia alebo jednoznačného potvrdzujúceho
        úkonu, ktorým dotknutá osoba vyjadruje súhlas so spracúvaním svojich
        osobných údajov, b) spracúvanie osobných údajov spracovateľská operácia
        alebo súbor spracovateľských operácií s osobnými údajmi alebo so súbormi
        osobných údajov, najmä získavanie, zaznamenávanie, usporadúvanie,
        štruktúrovanie, uchovávanie, zmena, vyhľadávanie, prehliadanie,
        využívanie, poskytovanie prenosom, šírením alebo iným spôsobom,
        preskupovanie alebo kombinovanie, obmedzenie, vymazanie, bez ohľadu na
        to, či sa vykonáva automatizovanými prostriedkami alebo
        neautomatizovanými prostriedkami, c) pseudonymizácia spracúvanie
        osobných údajov spôsobom, že ich nie je možné priradiť ku konkrétnej
        dotknutej osobe bez použitia dodatočných informácií, ak sa takéto
        dodatočné informácie uchovajú oddelene a vzťahujú sa na ne technické a
        organizačné opatrenia na zabezpečenie toho, aby osobné údaje nebolo
        možné priradiť identifikovanej fyzickej osobe alebo identifikovateľnej
        fyzickej osobe, d)šifrovanie transformácia osobných údajov spôsobom,
        ktorým opätovné spracúvanie je možné len po zadaní zvoleného parametra,
        ako je kľúč alebo heslo, e)profilovanie akákoľvek forma automatizovaného
        spracúvania osobných údajov spočívajúceho v použití osobných údajov na
        vyhodnotenie určitých osobných znakov alebo charakteristík týkajúcich sa
        fyzickej osoby, najmä na analýzu alebo predvídanie znakov alebo
        charakteristík dotknutej osoby súvisiacich s jej výkonnosťou v práci,
        majetkovými pomermi, zdravím, osobnými preferenciami, záujmami,
        spoľahlivosťou, správaním, polohou alebo pohybom, f) obmedzenie
        spracúvania osobných údajov označenie uchovávaných osobných údajov s
        cieľom obmedziť ich spracúvania v budúcnosti, g) porušenie ochrany
        osobných údajov porušenie bezpečnosti, ktoré vedie k náhodnému alebo
        nezákonnému zničeniu, strate, zmene alebo k neoprávnenému poskytnutiu
        prenášaných, uchovávaných osobných údajov alebo inak spracúvaných
        osobných údajov, alebo k neoprávnenému prístupu k nim, h) dotknutá osoba
        každá fyzická osoba, ktorej osobné údaje sa spracúvajú, i)
        prevádzkovateľ každý, kto sám alebo spoločne s inými vymedzí účel a
        prostriedky spracúvania osobných údajov a spracúva osobné údaje vo
        vlastnom mene; prevádzkovateľ alebo konkrétne požiadavky na jeho určenie
        môžu byť ustanovené v osobitnom predpise alebo medzinárodnej zmluvy,
        ktorou je Slovenská republika viazaná, ak takýto predpis alebo táto
        zmluva ustanovuje účel a prostriedky spracúvania osobných údajov;
        Prevádzkovateľom je aj Stanislav Pisarčík – Čajovňa Aura, pričom je
        možné, že osobné údaje budeme spracovávať aj ako spoloční
        prevádzkovatelia, j) spoloční prevádzkovatelia dvaja alebo viacerí
        prevádzkovatelia, ktorí si dohodou určia účel a prostriedky spracúvania
        osobných údajov, k) sprostredkovateľ každý, kto spracúva osobné údaje v
        mene prevádzkovateľa, l) príjemca každý, komu sa osobné údaje poskytnú
        bez ohľadu na to, či je treťou stranou; za príjemcu sa nepovažuje orgán
        verejnej moci, ktorý spracúva osobné údaje na základe osobitného
        predpisu alebo medzinárodnej zmluvy, ktorou je Slovenská republika
        viazaná, v súlade s pravidlami ochrany osobných údajov vzťahujúcimi sa
        na daný účel spracúvania osobných údajov, m) tretia strana každý, kto
        nie je dotknutou osobou, prevádzkovateľ, sprostredkovateľ alebo inou
        fyzickou osobou, ktorá na základe poverenia prevádzkovateľa alebo
        sprostredkovateľa spracúva osobné údaje. n) ďalšie definície nájdete na
        http://www.zakonypreludi.sk/hladanie?text=18%2F2018. Zásady spracúvania
        osobných údajov: a) Zákonnosť Osobné údaje možno spracúvať len zákonným
        spôsobom a tak, aby nedošlo k porušeniu základných práv dotknutej osoby.
        Zákonné spracúvanie osobných údajov je, ak je vykonávané na základe
        aspoň jedného z týchto právnych základov: -dotknutá osoba vyjadrila
        súhlas so spracúvaním svojich osobných údajov aspoň na jeden konkrétny
        účel, -spracúvanie osobných údajov je nevyhnutné na plnenie zmluvy v
        súvislosti s dotknutou osobou,-spracúvanie osobných údajov je nevyhnutné
        podľa osobitného predpisu alebo medzinárodnej zmluvy, ktorou je
        Slovenská republika viazaná, -spracúvanie osobných údajov je nevyhnutné
        na ochranu života, zdravia alebo majetku dotknutej osoby alebo inej
        fyzickej osoby, -spracúvanie osobných údajov je nevyhnutné na splnenie
        úlohy vo verejnom záujme alebo pri výkone verejnej moci zverenej
        prevádzkovateľovi, alebo-spracúvanie osobných údajov je nevyhnutné na
        účel oprávnených záujmov prevádzkovateľa alebo tretej strany okrem
        prípadov, keď nad týmito záujmami prevažujú záujmy alebo práva dotknutej
        osoby vyžadujúce si ochranu osobných údajov, najmä ak je dotknutou
        osobou dieťa; tento právny základ sa nevzťahuje na spracúvanie osobných
        údajov orgánmi verejnej moci pri plnení ich úloh. b) Zásada obmedzenia
        účelu Osobné údaje sa môžu získavať len na konkrétne určený, výslovne
        uvedený a oprávnený, legitímny účel a nesmú sa ďalej spracúvať spôsobom,
        ktorý nie je zlučiteľný s týmto účelom. Výnimku tvoria tzv.
        privilegované účely, ktoré ochrana osobných údajov automaticky považuje
        za zlučiteľné s pôvodnými účelmi. Ide o účely archivácie vo verejnom
        záujme, účely vedeckého alebo historického výskumu a štatistické účely.
        c) Zásada minimalizácie osobných údajov Spracúvané osobné údaje musia
        byť primerané, relevantné a obmedzené na nevyhnutný rozsah daný účelom,
        na ktorý sa spracúvaj. d) Zásada správnosti Spracúvané osobné údaje
        musia byť správne a podľa potreby aktualizované, pričom sa musia prijať
        primerané a účinné opatrenia na zabezpečenie toho, aby sa osobné údaje,
        ktoré sú nesprávne z hľadiska účelov, na ktoré sa spracúvajú, bez
        zbytočného odkladu vymazali alebo opravili. Táto zásada však nezbavuje
        dotknutú osobu zodpovednosti poskytovať správne osobné údaje. e)Zásada
        minimalizácie uchovávania Osobné údaje musia byť uchovávané vo forme,
        ktorá umožňuje identifikáciu dotknutých osôb najviac dovtedy, kým je to
        potrebné na účely, na ktoré sa osobné údaje spracúvajú. Doba uchovávania
        môže vyplývať z osobitných predpisov, alebo ak sa majú spracúvať výlučne
        na účel archivácie, na vedecký účel, na účel historického výskumu alebo
        na štatistický účel a ak sú pri tom dodržané primerané záruky ochrany
        práv dotknutej osoby spočívajúce v zavedení primeraných a účinných
        technických a organizačných opatrení, najmä na zabezpečenie dodržiavania
        zásady minimalizácie údajov. f)Zásada integrity a dôvernosti Osobné
        údaje musia byť spracúvané spôsobom, ktorý prostredníctvom primeraných
        technických a organizačných opatrení zaručuje primeranú bezpečnosť
        osobných údajov vrátane ochrany pred neoprávneným spracúvaním osobných
        údajov, nezákonným spracúvaním osobných údajov, náhodnou stratou
        osobných údajov, výmazom osobných údajov alebo poškodením osobných
        údajov. g)Zásada zodpovednosti Prevádzkovateľ je zodpovedný za
        dodržiavanie základných Zásad spracúvania osobných údajov, za súlad
        spracúvania osobných údajov so Zásadami spracúvania osobných údajov a je
        povinný tento súlad so Zásadami spracúvania osobných údajov na
        požiadanie Úradu na ochranu osobných údajov Slovenskej republiky
        preukázať. Kategórie dotknutých osôb a osobných údajov, na aké účely ich
        spracúvame, právny základ spracúvania: V rámci našej činnosti
        spracovávame osobné údaje týchto dotknutých osôb (kategórie): Právnická
        osoba Stanislav Pisarčík – Čajovňa Aura. Zmluvní partneri – subjekty, s
        ktorými vstupujeme do zmluvných vzťahov, vrátane subjektov, s ktorými
        sme do zmluvných vzťahov nevstúpili, ale boli predmetom rokovaní. V
        rámci našej činnosti spracovávame nasledovné osobné údaje (kategórie):
        Osobné údaje – napr. meno, priezvisko, pohlavie, trvalý alebo obdobný
        pobyt (sídlo, miesto podnikania), emailová adresa, telefónne číslo,
        titul, funkcia, dátum narodenia, Zmluvné údaje – osobné údaje, meno a
        priezvisko fyzickej osoby a ak ide o podnikateľa aj jeho obchodné meno,
        obchodné meno právnickej osoby, sídlo, pobyt, doručovacia adresa, obsah
        zmluvy (vecný, časový), platobné a fakturačné údaje, údaje o
        korešpondencii a komunikácii, zápis v príslušnej evidencii (obchodný
        register, živnostenský register, evidencia podľa osobitného zákona),
        Zákonom vyžadované údaje – všetky údaje vyžadované všeobecne záväznými
        právnymi predpismi a právnymi normami Európskej únie, Platobné a
        fakturačné údaje – číslo bankového spojenia, identifikačné a daňové
        identifikačné číslo, identifikačné číslo pre DPH, Údaje o korešpondencii
        a komunikácii – emailová adresa, korešpondenčná adresa. Osobné údaje
        môžu byť spracúvané aj v automatizovanej podobe, vychádzajúcej z našej
        internetovej stránky, do ktorej má prístup štatutár a/alebo admin,
        ktorého môže môžu naša spoločnosť udeliť oprávnenie na všetky úkony v
        systéme. Účel a cieľ spracúvania osobných údajov: a)Objednávanie našich
        služieb: -meno, priezvisko (za účelom identifikácie nájomcu), -dátum
        narodenia (za účelom identifikácie nájomcu a vylúčenia uzavretia zmluvy
        s neplnoletou osobou), -emailová adresa a telefonický kontakt (za účelom
        vzájomnej komunikácie),-adresa trvalého pobytu alebo obdobného pobytu
        (za účelom vystavenia a zaslania daňového dokladu a plnenia
        zmluvy).Právnym základom pre tento účel je nájomná zmluva medzi našou
        spoločnosťou a nájomcami. Budeme spracúvať osobné údaje o všetkých
        našich zákazníkoch. Lehota na výmaz osobných údajov je 12 rokov.
        b)Informácie o zmluvných partneroch – evidencia a administratíva: -meno,
        priezvisko, titul (fyzická osoba)/obchodné meno (právnická osoba),
        miesto podnikania (fyzická osoba)/sídlo (právnická osoba), IČO, DIČ, IČ
        pre DPH (za účelom vystavenia a zaslania daňového dokladu) a iné údaje
        podľa osobitných predpisov,-platobné údaje. Právnym základom pre tento
        účel je plnenie zmluvných a zákonných povinností, Obchodný zákonník,
        Občiansky zákonník. Budeme spracúvať osobné údaje, zmluvné údaje,
        platobné a fakturačné údaje, údaje o korešpondencii a komunikácii o
        našich zákazníkoch, dodávateľoch a odberateľoch, spolupracujúcich
        osobách a pod. Lehota na výmaz osobných údajov je 10 rokov. c)Mzdy,
        personalistika a účtovníctvo, dane, zdravotné a sociálne poistenie
        (povinnosti finančnej povahy): -meno, priezvisko, titul zamestnanca,
        adresa trvalého pobytu, výška mzdy/príjmu, manželia, manželky,
        vyživované deti zamestnancov, rodičia vyživovaných detí zamestnancov,
        blízke osoby. Právnym základom pre tento účel je plnenie zákonných
        povinností (napr. Zákonník práce, zákon o zdravotnom poistení, zákon o
        sociálnom poistení, o dani z príjmov a pod.). Budeme spracúvať údaje
        vyžadované podľa právnych predpisov a predpisov Európskej únie na účely
        plnenia našich povinností.. Lehota na výmaz osobných údajov je 10 rokov.
        d)Marketing: -Obrazové vyobrazenia zákazníkov. Právnym základom pre
        tento účel je predovšetkým Občiansky zákonník a súhlas dotknutej osoby
        alebo v odôvodnených prípadoch náš oprávnený záujem. Osobné údaje na
        marketingové účely budeme spracúvať na základe vopred vyžiadaných
        osobitných súhlasov dotknutých osôb. Lehota na výmaz osobných údajov je
        12 rokov. e) Korporátne informácie: – Meno, priezvisko, titul, rodné
        číslo, miesto a dátum narodenia, trvalý pobyt, meno a priezvisko rodičov
        spoločníkov, – Meno, priezvisko, titul, rodné číslo, miesto a dátum
        narodenia, trvalý pobyt, meno a priezvisko rodičov konateľov. Právnym
        základom pre tento účel je Obchodný zákonník, živnostenský zákon. Lehota
        na výmaz osobných údajov je daný trvaním právnej subjektivity oprávnenej
        osoby. Stanislav Pisarčík – Čajovňa Aura ako prevádzkovateľ spracúva
        Vaše osobné údaje, ako dotknutej osoby, prostredníctvom: 1.Konateľov,
        2.vedenie účtovníctva, miezd a verejného poistenia pre oprávnenú osobu
        na zmluvnom alebo zákonnom základe. Vaše osobné údaje, ako osobné údaje
        dotknutých osôb, budú uchovávané obmedzenú dobu a budú vymazané
        najneskôr do 30 dní po tom, ako prestanú byť potrebné pre účely, na
        ktoré nám boli odovzdané. Vo väčšine prípadov to bude na obdobie trvania
        nášho zmluvného vzťahu, pokiaľ osobitný právny predpis nebude dobu
        uchovávania dokladov inak. Osobné údaje môžeme spracovávať aj dlhšiu
        dobu v prípade nášho oprávneného záujmu. Práva dotknutých osôb: Vaše
        práva si môžete uplatniť zaslaním emailovej správy alebo písomnej
        žiadosti na naše kontaktné údaje, ktorými sú Stanislav Pisarčík –
        Čajovňa Aura ,so sídlom Štefánikova 16, 05901, Spišská Belá. V žiadosti
        je potrebné uviesť meno, priezvisko, e-mailovú adresu, prípadne
        telefonický kontakt a adresu trvalého bydliska. Ak nám uvedené údaje
        neposkytnete, nebude možné vyhovieť Vašej žiadosti. Tieto informácie od
        Vás požadujeme preto, aby sme si overili Vašu totožnosť a neposkytli
        Vaše osobné údaje neoprávnenej osobe. Vaše práva ako dotknutej osoby sú
        legislatívne upravené v ust. § 19 a nasl. Zákona a čl. 12 a nasl.
        Nariadenia takto: Právo na prístup k údajom Máte právo získať od nás
        potvrdenie o tom, či sa o Vás spracúvajú osobné údaje a v prípade, ak
        Vaše osobné údaje spracúvame, poskytneme vám informáciu o tom, aké údaje
        o Vás spracúvame, za akým účelom, komu boli Vaše osobné údaje
        poskytnuté, či boli prenesené do tretej krajiny a ako dlho budeme Vaše
        osobné údaje uchovávať. Právo na opravu Ak sa domnievate, že o vás
        spracúvame nesprávne osobné údaje, môžete využiť toto právo priamo
        obrátením sa na nás a požiadaním o ich opravu. Právo na vymazanie (právo
        na zabudnutie) Máte právo na vymazanie Vašich osobných údajov, ktoré o
        vás spracúvame, v prípade ak sú splnené nasledovné podmienky a
        neuplatňujú sa zákonné výnimky: -údaje už nie sú potrebné na účely, na
        ktoré sa získavali, -odvoláte súhlas na spracúvanie Vašich osobných
        údajov a na ich spracúvanie neexistuje iný právny základ, -namietnete na
        základe Vašej konkrétnej situácie spracúvanie Vašich osobných údajov,
        spracúvaných na základe oprávneného záujmu a neprevažujú oprávnené
        dôvody na spracúvanie alebo namietnete spracúvanie za účelom priameho
        marketingu, -osobné údaje sa spracúvali nezákonne. V prípade, ak by boli
        Vaše osobné údaje zverejnené a Vy si u nás uplatníte právo na vymazanie,
        vymažeme aj takéto zverejnené osobné údaje. Zároveň budeme informovať
        ostatných príjemcov Vašich osobných údajov, že ste si uplatnili právo na
        vymazanie a mali by teda vymazať všetky odkazy na Vaše osobné údaje, ak
        to bude technicky možné so zreteľom na dostupnú technológiu a náklady na
        vykonanie týchto opatrení. V prípade, ak si u nás zrušíte konto,
        pristúpime k vymazaniu údajov, pri ktorých je to možné a k zrušeniu
        konta do 10 dní. Z technických dôvodov (napr. z dôvodu zálohovania
        údajov) sa však môže stať, že niektoré údaje budú vymazané po dlhšej
        dobe, ktorá by však nemala presiahnuť 60 dní. Právo na obmedzenie
        spracúvania Máte tiež právo nás požiadať o dočasné obmedzenie
        spracúvania, a to v týchto prípadoch: -ak sa domnievate, že o Vás
        spracúvame nesprávne osobné údaje, do doby, než sa overí správnosť
        týchto osobných údajov, -spracúvanie Vašich osobných je protizákonné a
        vy sa rozhodnete namiesto vymazania osobných údajov žiadať obmedzenie
        ich spracúvania, -už nepotrebujeme osobné údaje na účely spracúvania,
        avšak tieto sú potrebné pre Vaše vlastné účely preukázania, uplatňovania
        alebo obhajovania Vašich právnych nárokov, -v prípade ak namietate
        spracúvanie Vašich osobných údajov, do doby overenia či oprávnené dôvody
        pre spracúvanie osobných údajov prevažujú nad Vašimi oprávnenými
        záujmami. Právo na prenosnosť údajov Máte právo získať osobné údaje,
        ktoré o Vás spracúvame na základe súhlasu a/alebo zmluvy a spracúvame
        ich automatizovanými prostriedkami, a to v štruktúrovanom, bežne
        používanom a strojovo čitateľnom formáte. Tieto údaje môžete preniesť aj
        inej osobe, preto ak je to technicky možné, prenesieme na Vašu žiadosť
        Vaše osobné údaje priamo k Vami zvolenému prevádzkovateľovi. Právo
        namietať Máte právo namietať z dôvodov týkajúcich sa Vašej konkrétnej
        situácie spracúvanie Vašich osobných údajov, ktoré je vykonávané na
        základe nášho oprávneného záujmu, vrátane práva namietať proti
        profilovaniu založenému na našom oprávnenom záujme. Vždy máte tiež právo
        namietať voči spracúvaniu Vašich osobných údajov, ak sa tieto spracúvajú
        na účely priameho marketingu. Podávanie sťažností: V prípade, ak sa
        domnievate, že spracúvanie Vašich osobných údajov našou spoločnosťou je
        v rozpore s platnými právnymi predpismi na ochranu osobných údajov, máte
        právo podať sťažnosť Úrad na ochranu osobných údajov Slovenskej
        republiky, prostredníctvom dataprotection.gov.sk. Všeobecný opis
        technických a organizačných bezpečnostných opatrení: Stanislav Pisarčík
        – Čajovňa Aura – ako prevádzkovateľ, sme so zreteľom na najnovšie
        poznatky, na náklady na vykonanie opatrení, na povahu, rozsah, kontext a
        účel spracúvania osobných údajov a na riziká s rôznou pravdepodobnosťou
        a závažnosťou pre práva fyzických osôb prijali nasledovné primerané
        technické a organizačné opatrenia na zaistenie úrovne bezpečnosti
        primeranej tomuto riziku: -zabezpečenie trvalej dôvernosti, integrity,
        dostupnosti a odolnosti systémov spracúvania osobných údajov,-proces
        obnovy dostupnosti osobných údajov a prístup k nim v prípade fyzického
        incidentu alebo technického incidentu,-náhodné hodnotenia účinnosti
        technických a organizačných opatrení na zaistenie bezpečnosti
        spracúvania osobných údajov, -využívanie služby internetu iba verejných
        služieb WWW – world wide web a FTP – file transfer protocol za účelom
        plnenia pracovných úloh, dodržiavanie bezpečnostných opatrení prijatých
        prevádzkovateľom za účelom zabezpečenia ochrany osobných
        údajov,-informačná technika (počítače, notebooky, USB kľúč, a pod.) bude
        umiestňovaná výhradne v priestoroch na to určených; miestnosť, v ktorej
        sa nachádza informačná technika, bude pri každom odchode oprávnenej
        osoby uzamknutá a po skončení pracovnej doby bude oprávnená osoba
        povinná vypnúť počítač a uzamknúť skrine s materiálmi obsahujúcimi
        osobné údaje,-zabezpečenie antivírusovej ochrany pracovných staníc
        sledovaním toho, či správne funguje primárne určený softvérový systém,
        ktorý je automaticky pravidelne aktualizovaný,-zákaz odinštalovania,
        zablokovania alebo zmenu konfigurácie antivírusovej ochrany,-dôsledne
        dodržiava pravidiel ochrany prístupových práv (nenecháva svoje
        prístupové heslá voľne dostupné, na obrazovke, zapísané vedľa PC,
        neprezradí ich neoprávnenej osobe), -v prípade používania cudzích
        technických zariadení na spracúvanie osobných údajov bude dbať na to,
        aby boli primerane zabezpečené (inštalovaný len legálny softvér,
        aktuálna antivírusová ochrana, zapnutý aspoň základný firewall,
        identifikácia a autentifikácia). Kontaktné údaje: Ak nám chcete oznámiť
        porušenie používania osobných údajov alebo zaslať žiadosť týkajúcu sa
        práv dotknutej osoby, môžete tak urobiť prostredníctvom pošty na adresu
        Štefánikova 16, 05901 Spišská Belá.
      </p>
      <p>
        VII. Záverečné ustanovenia 1. VOP nadobúdajú účinnosť dňom 15.02.2022.
        2. Pokiaľ Zmluvu uzatvára na strane Nájomcu fyzická osoba, ktorá nie je
        podnikateľom, dohodli sa Zmluvné strany výslovne, že záväzkový vzťah
        založený zmluvou sa bude spravovať zákonom č.513/1991 Zb. Obchodný
        zákonník v znení neskorších predpisov. 3. Ak niektoré ustanovenia Zmluvy
        nie sú celkom alebo sčasti platné alebo účinné alebo neskôr stratia
        platnosť alebo účinnosť, nie je tým dotknutá platnosť alebo účinnosť
        ostatných ustanovení Zmluvy. Namiesto neplatných alebo neúčinných
        ustanovení Zmluvy, a na vyplnenie medzier, sa použije právna úprava,
        ktorá, pokiaľ je to právne možné, sa čo najviac približuje zmyslu a
        účelu Zmluvy. 4. VOP spolu s Odovzdávacím protokolom, Zmluvou o nájme
        hnuteľných veci, tvoria neoddeliteľnú súčasť Zmluvy. Súčasťou zmluvného
        vzťahu Zmluvných strán je aj Cenník Prenajímateľa platný v čase
        uzavretia zmluvy Zmluvy, ktorý je zverejnený na internetovej stránke
        Prenajímateľa. 5. Odchylné ustanovenia v Zmluve majú prednosť pred VOP.
      </p>
    </div>
  );
};

export default Terms;
